import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";

import './assets/scss/abanda.scss';

import AppRoutes from './routes';

import Header from './components/grid/header';
import Footer from './components/grid/footer';

// import DialogContent from './components/dialogs/dialog-content';
import DialogVerification from './components/dialogs/dialog-verification';
import DialogAddedToCart from './components/dialogs/dialog-addedtocart';

const App = inject("stores")(
    observer(
        class App extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeAuth = this.props.stores.storeAuth;
                this.storeUi = this.props.stores.storeUi;
                this.storeCart = this.props.stores.storeCart;
                
                const data = this.storeAuth.getAuthDataFromLS();

                this.state = {
                }
            }

            componentDidMount = () => {
                this.storeUi.openDialogVerification();
            }

            componentDidUpdate = async() => {

            }

            render() {
                return(
                    <React.Fragment>
                        <div 
                            className="wrapper-app"
                        >
                            <Header 
                                isLoggedin = {this.storeAuth.isLoggedin} 
                            />

                            <div 

                            >
                                <AppRoutes />
                            </div>

                            <Footer 
                                isLoggedin = {this.storeAuth.user.isLoggedin}
                            />
                        </div>

                        {/* {this.storeUi.app_interface.isDialogContentOpen &&
                            <DialogContent
                                show = {this.storeUi.app_interface.isDialogContentOpen}
                                onClose = {() => this.storeUi.closeDialogContent()}
                                title = {this.storeUi.content_component_title}
                                buttons = {
                                    [{
                                        text: "Sluit venster",
                                        onClick: () => {this.storeUi.closeDialogContent()},
                                        btnClass: "--primary"
                                    }]
                                }
                                
                                >
                                    {this.storeUi.content_component}
                            </DialogContent>
                        } */}

                        {this.storeUi.app_interface.isDialogVerificationOpen && !this.storeAuth.isLoggedin && this.storeUi.checkMsgVerification() &&
                            <DialogVerification
                                show = {this.storeUi.app_interface.isDialogVerificationOpen}
                                onClose = {() => this.storeUi.closeDialogVerification()}
                                title = {this.storeUi.content_component_title}
                                buttons = {
                                    [{
                                        text: "Close message",
                                        onClick: () => {this.storeUi.closeDialogVerification()},
                                        btnClass: "--primary --active"
                                    }]
                                }
                                
                                >
                                    {this.storeUi.content_component}
                            </DialogVerification>
                        }

                        {this.storeUi.app_interface.isDialogAddedToCartOpen && 
                            <DialogAddedToCart
                                show = {this.storeUi.app_interface.isDialogAddedToCartOpen}
                                onClose = {() => this.storeUi.closeDialogAddedToCart()}
                                title = {this.storeUi.content_component_title}
                                buttons = {
                                    [{
                                        text: "Close message",
                                        onClick: () => {this.storeUi.closeDialogAddedToCart()},
                                        btnClass: "--primary --active"
                                    }]
                                }
                                
                                >
                                    {this.storeUi.content_component}
                            </DialogAddedToCart>
                        }
                    </React.Fragment>
                )
            }
        }
    )
)

export default App;

import React, { Component } from 'react';
import { reaction, runInAction } from "mobx";
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import RowText from '../form-rows/row-text';
import FormFeedbackRequired from '../../elements/forms/form-feedback-required';
import TemplateForms from '../../templates/template-forms';

const FormEmail = inject("stores") (
    observer (
        class FormEmail extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeAuth = this.props.stores.storeAuth;
                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                this.storeForms = this.props.stores.storeForms;

                this.state = {
                    formControls: {          
                        email: {
                            type: "email",
                            value: '',
                            validationRules: {
                                isRequired: true,
                                isEmail: false
                            },
                            valid: false
                        }
                    },
                    formIsValid: false,
                    btn_class: '--disabled'
                }
            }

            validateRow = (name, value, valid) => { 
                this.props.showVerification(false);

                const updatedControls = this.storeForms.validateRow(this.state.formControls, name, value, valid);
                this.storeUi.clearFormFeedback();
                
                this.setState({
                    formControls: updatedControls,
                }, () => this.validateForm()); 
            }

            validateForm = () => {
                const formIsValid = this.storeForms.validateForm(this.state.formControls);

                this.setState({
                    formIsValid: formIsValid,
                    btn_class: formIsValid 
                        ?   '--active' 
                        :   '--disabled'
                });
            }

            loginUser = async(e) => {
                if (this.state.formIsValid) {
                    this.setState({
                        formIsValid: false,
                        btn_class: '--disabled'
                    }, () => this.props.checkVerification(this.state.formControls));
                }
            }

            render() {

                return (
                    <TemplateForms
                        formClass = "login"
                        type = 'login'
                        msg_success = {i18n.t("form_feedback.success_login")}
                    >
                        <form className="form">
                            <div className="form-fieldset">
                                {Object.keys(this.state.formControls).map((key, i) => (
                                    <React.Fragment>
                                        {(this.state.formControls[key].type === "text" || this.state.formControls[key].type === "email" || this.state.formControls[key].type === "password") &&
                                            <RowText 
                                                type = {this.state.formControls[key].type}
                                                name = {key}
                                                value = {this.state.formControls[key].value}
                                                validationRules = {this.state.formControls[key].validationRules}
                                                onValidate = {this.validateRow}
                                            />
                                        }
                                    </React.Fragment>
                                ))}
                            </div>
                        </form>
                            
                        <div className="form-row form-row--buttons">
                            <button className={"btn btn--primary " + this.state.btn_class} onClick={(e) => this.loginUser(e)}>{i18n.t("button.send_code")}</button>
                        </div>

                        {'error' in this.storeUi.formFeedback && (
                            <div className="form-row form-row--error">
                                <p 
                                    dangerouslySetInnerHTML={{ __html: i18n.t("errors.error_login")}}
                                />
                            </div>
                        )}
                    </TemplateForms>
                )
            }
        }
    )
)

export default withTranslation()(FormEmail);
